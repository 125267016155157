import Vue from 'vue'
// import keep from '@bye_past/vue-keep';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router'; // 导入 Vue Router 配置
import store from './store'; // 导入 Vuex store
import service from './service'; // 导入 axios
import Vant from 'vant';
import 'vant/lib/index.css';
import uni from '@/assets/js/uni.webview.1.5.4.js';
import MyPlugin from './plugin';
// import uniFunction from './window-function';
// Vue.use(keep, router);
Vue.use(Vant);
Vue.use(Vuex)
Vue.prototype.$axios = service;
Vue.use(MyPlugin);
// Vue.use(uniFunction);
Vue.use(uni);
window.uni = uni;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');