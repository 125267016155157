import axios from 'axios';
import router from '@/router'
const api = axios.create({
  // baseURL: 'http://192.168.1.26:8080/api/middle',
  baseURL: 'https://planner.yikaotianbao.com/api/middle',
});

// 请求拦截器
api.interceptors.request.use(config => {
  let Authorization = localStorage.getItem('Authorization');
  if (Authorization && !config.url.includes('login') && !config.url.includes('agentConfig') && !config.url.includes('sms')) {
    config.headers['Authorization'] = 'Bearer ' + Authorization
  }
  config.headers['Agentid'] = 1;
  // 在发送请求之前做些什么
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 响应拦截器
api.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.code == 401) {
    console.log('token过期了');
    console.log(router);
    localStorage.setItem('Authorization', '');
    window.uni.postMessage({
      data: {
        name: 'evalJs',
        value: "uni.setStorageSync('token','')"
      }
    })
    router.replace('/login');
  }
  // 对响应数据做点什么
  if (response.data.code == 201) {
    router.replace('/score');
  }
  return response.data;
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default api;