import router from '@/router'
export default {
  install(Vue) {
    // console.log(options);
    Vue.prototype.$onBack = function(){
      router.back();
    }
    Vue.prototype.$pluginMethod = function () {
      // 你的代码
    };
    Vue.prototype.$toSchoolDetail = function (school,type) {
      school.type  = school.type ? school.type  : type;
      // 1高中 2初中
      if (school.type == 1) {
        router.push({
          path: '/schoolDetail',
          query: {
            id: school.schoolId,
            schoolName: school.schoolName
          }
        })
      } else {
        router.push({
          path: '/middleSchoolDetail',
          query: {
            id: school.juniorId,
            schoolName: school.schoolName
          }
        })
      }
    }
  }
};