import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()], // 添加这个插件
  state: {
    vuex_system: 'android', //是苹果还是安卓
    vuex_user: {},
    vuex_config: {},
    vuex_env_available: false, // 是否支持一键登录
    vuex_model: 'h5', // 平台
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.vuex_config = data
    },
    SET_USER(state, data) {
      state.vuex_user = data
    },
    SET_ENV_AVAILABLE(state, data) {
      state.vuex_env_available = data
    },
    SET_MODEL(state, data) {
      state.vuex_model = data
    },
    SET_SYSTEM(state, data) {
      state.vuex_system = data
    }
  },
  actions: {},
  modules: {}
});