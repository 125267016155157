import Vue from 'vue';
import Router from 'vue-router';

import store from './store';
import wx from 'weixin-js-sdk';

Vue.use(Router);

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}
const router = new Router({
	mode: 'history',
	base: '/', // 确保这里是一个有效的字符串
	routes: [{
			path: '/',
			name: 'homePage',
			component: () => import('./pages/homePage.vue')
		},
		{
			path: '/user-center',
			name: 'userPage',
			component: () => import('./pages/userPage.vue')
		},
		{
			path: '/score',
			name: 'scorePage',
			component: () => import('./pages/scorePage.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('./pages/loginPage.vue')
		},
		{
			path: '/card',
			name: 'card',
			component: () => import('./pages/cardPage.vue')
		},
		{
			path: '/about',
			name: 'about',
			component: () => import('./pages/aboutPage.vue')
		},
		{
			path: '/plan',
			name: 'plan',
			component: () => import('./pages/planPage.vue')
		},
		{
			path: '/areaQuota',
			name: 'areaQuota',
			component: () => import('./pages/areaQuotaPage.vue')
		},
		{
			path: '/areaQuotaZhiBiao',
			name: 'areaQuotaZhiBiao',
			component: () => import('./pages/areaQuotaZhiBiaoPage.vue')
		},
		{
			path: '/schoolQuota',
			name: 'schoolQuota',
			component: () => import('./pages/schoolQuotaPage.vue')
		},
		{
			path: '/schoolDetail',
			name: 'schoolDetail',
			component: () => import('./pages/schoolDetailPage.vue')
		}, {
			path: '/middleSchoolDetail',
			name: 'middleSchoolDetail',
			component: () => import('./pages/middleSchoolDetailPage.vue')
		},
		{
			path: '/kf',
			name: 'kf',
			component: () => import('./pages/kfPage.vue')
		},
		{
			path: '/tongKao',
			name: 'tongKao',
			component: () => import('./pages/tongKaoPage.vue')
		},
		{
			path: '/ziZhao',
			name: 'ziZhao',
			component: () => import('./pages/ziZhaoPage.vue')
		},
		{
			path: '/setting',
			name: 'setting',
			component: () => import('./pages/settingPage.vue')
		},
		{
			path: '/area',
			name: 'area',
			component: () => import('./pages/areaPage.vue')
		},
		{
			path: '/news',
			name: 'news',
			component: () => import('./pages/newsPage.vue')
		},
		{
			path: '/map',
			name: 'map',
			component: () => import('./pages/mapPage.vue')
		},
		{
			path: '/search',
			name: 'search',
			component: () => import('./pages/searchPage.vue')
		},
		{
			path: '/buyVip',
			name: 'buyVip',
			component: () => import('./pages/buyVipPage.vue')
		},
		{
			path: '/batch',
			name: 'batch',
			component: () => import('./pages/batchPage.vue')
		},
		{
			path: '/interval',
			name: 'interval',
			component: () => import('./pages/intervalPage.vue')
		},
		{
			path: '/intervalInfo',
			name: 'intervalInfo',
			component: () => import('./pages/intervalInfoPage.vue')
		},
		{
			path: '/recommend',
			name: 'recommend',
			component: () => import('./pages/recommendPage.vue'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/bind',
			name: 'bind',
			component: () => import('./pages/bindPage.vue'),
			meta: {
				keepAlive: true
			}
		},
		{
			path: '/collect',
			name: 'collect',
			component: () => import('./pages/collectPage.vue')
		},
		{
			path: '/complain',
			name: 'complain',
			component: () => import('./pages/complainPage.vue')
		},
		{
			path: '/shop/addressList',
			name: 'addressList',
			component: () => import('./pages/shop/addressListPage.vue')
		},
		{
			path: '/shop/addressDetail',
			name: 'addressDetail',
			component: () => import('./pages/shop/addressDetailPage.vue')
		},
		{
			path: '/shop/goodList',
			name: 'goodList',
			component: () => import('./pages/shop/goodListPage.vue')
		},
		{
			path: '/shop/goodInfo',
			name: 'goodInfo',
			component: () => import('./pages/shop/goodInfoPage.vue')
		},
		{
			path: '/shop/createOrder',
			name: 'createOrder',
			component: () => import('./pages/shop/createOrderPage.vue')
		}
	]
});

router.beforeEach((to, from, next) => {
	if (store.vuex_model == 'weixin' && (to.fullPath == '/buyVip' || to.fullPath == '/login')) {
		if (to.fullPath == '/buyVip') {
			wx.miniProgram.navigateTo({
				url: "/pages/payment/payment"
			})
		}
		if (to.fullPath == '/login') {
			wx.miniProgram.navigateTo({
				url: "/pages/wx-login/wx-login"
			})
		}
		return
	} else {
		if (store.vuex_system == 'ios' && to.fullPath == '/buyVip') {
			next('/shop/goodInfo');
			return;
		}
		window.uni.postMessage({
			data: {
				name: 'router',
				value: to.fullPath
			}
		})
		next();
	}
});
export default router;