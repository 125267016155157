<template>
	<div id="app">
		<!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive> -->
		<!-- v-if="!$route.meta.keepAlive" -->
		<router-view></router-view>
	</div>
</template>

<script>
import wx from 'weixin-js-sdk'; // 导入微信jssdk
import { Toast } from 'vant';
export default {
	name: 'App',
	created() {
		// this.onShare();
		const urlParams = new URLSearchParams(window.location.search);
		//平台区分
		const model = urlParams.get('model');
		const system = urlParams.get('system');
		this.$store.commit('SET_SYSTEM', system);
		this.$store.vuex_system = system;
		this.$store.vuex_model = model;
		this.$store.commit('SET_MODEL', model);
		//token
		const token = urlParams.get('token');
		if (token) {
			localStorage.setItem('Authorization', token);
		}
		let geXingHua = localStorage.getItem('geXingHua');
		if (!geXingHua) {
			localStorage.setItem('geXingHua', true);
		}
		// 基本配置
		this.agentConfig();
		this.initUniFunction();
	},
	methods: {
		// 获取url参数
		// getUrlKey(name) {
		// 	return (
		// 		decodeURIComponent(
		// 			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
		// 				location.href
		// 			) || [, ""])[1].replace(/\+/g, "%20")
		// 		) || null
		// 	);
		// },
		agentConfig() {
			this.$axios.get('/agentConfig/getConfig').then((res) => {
				if (res.code == 200) {
					this.$store.commit('SET_CONFIG', res.data);
				}
			})
		},
		initUniFunction() {
			const that = this;
			document.addEventListener('UniAppJSBridgeReady', function () {
				// 将函数挂载到window对象上
				window.check_env_available = function (result) {
					that.$store.vuex_env_available = result;
					that.$store.commit('SET_ENV_AVAILABLE', result); // 使用commit调用mutation来改变状态
				}
				window.set_version = function (version) {
					if (version) {
						localStorage.setItem('version', version);
					}
				}
				// 一键登录完成回调
				window.login_token = function (code) {
					// console.log(code)
					that.oneClickLogin(code)
				}
				// 支付完成回调
				window.pay_iap_back = function (data) {
					if (data) {
						let { payment, transactionIdentifier, transactionReceipt } = data;
						let { productid } = payment;
						let params = {
							productId: productid,
							transactionIdentifier: transactionIdentifier,
							transactionReceipt: transactionReceipt
						}
						that.$axios.post('/pay/applePay', params).then((res) => {
							if (res.code === 200) {
								that.$router.back();
							} else {
								Toast.fail('支付失败');
							}
						});
					} else {
						Toast.fail('支付失败');
					}
				}
				// 支付完成回调
				window.pay_back = function (code) {
					if (code) {
						that.$router.back();
						// that.$router.replace('/user-center');
					} else {
						Toast.fail('支付失败');
					}
				}
				// 微信授权回调
				window.wx_auth_back = function (code) {
					that.$bus.$emit('wxAuthCallBack', code);
				}
				// 苹果授权回调
				window.apple_auth_back = function (code) {
					that.$bus.$emit('appleAuthCallBack', code);
				}
				// app端回调
				window.want_back = function () {
					// window.history.back();
					that.$router.back();
					// window.uni.postMessage({
					//   data: {
					//     name: 'h5_want_back',
					//     url: that.$route.fullPath
					//   }
					// })
					// if (window.history.length > 2) {
					//   console.log('返回上一级');
					//   window.history.back();
					//   window.uni.postMessage({
					//     name: 'h5_want_back',
					//     url: that.$route.fullPath
					//   })
					// } else {
					//   window.uni.postMessage({
					//     name: 'h5_want_close',
					//     url: that.$route.fullPath
					//   })
					//   console.log('关闭webview');
					// }
				}
			})
		},
		// 一键登录
		oneClickLogin(code) {
			let params = {
				AccessToken: code
			}
			this.$axios.get('/oneClickLogin', {
				params
			}).then((res) => {
				if (res.code == 200) {
					Toast.success('登陆成功');
					let Authorization = res.data;
					localStorage.setItem('Authorization', Authorization);
					window.uni.postMessage({
						data: {
							name: 'evalJs',
							value: "uni.setStorageSync('token','" + Authorization + "')"
						}
					})
					this.$router.push('/');
				} else {
					Toast.fail('登录失败');
				}
			})
		},
		onShare() {
			// 检查微信JS-SDK是否准备好
			if (typeof wx !== 'undefined') {
				this.$axios.get('/home/getJSSDK').then((res) => {
					if (res.code == 200) {
						let {
							jssdk,
							little_title,
							share_img,
							title
						} = res.data;
						// 配置分享信息
						wx.config({
							debug: false, // 开启调试模式，调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息可打印在页面上。
							appId: jssdk.appId,
							timestamp: jssdk.timestamp,
							nonceStr: jssdk.nonceStr,
							signature: jssdk.signature,
							jsApiList: ['onMenuShareTimeline',
								'onMenuShareAppMessage'
							] // 必填，需要使用的JS接口列表
						});

						// 注册分享到朋友圈的事件
						wx.ready(function () {
							// 设置分享朋友圈的 title、desc 和 imageurl
							wx.onMenuShareTimeline({
								title: title, // 分享标题
								desc: little_title, // 分享描述
								link: window.location.href, // 分享链接
								imgUrl: share_img, // 分享图标
								success: function () {
									// 用户确认分享后执行的回调函数
									console.log('分享成功');
								},
								cancel: function () {
									// 用户取消分享后执行的回调函数
									console.log('分享取消');
								}
							});
							wx.onMenuShareAppMessage({
								title: title, // 分享标题
								desc: little_title, // 分享描述
								link: window.location.href, // 分享链接
								imgUrl: share_img, // 分享图标
								success: function () {
									// 用户确认分享后执行的回调函数
									console.log('分享成功');
								},
								cancel: function () {
									// 用户取消分享后执行的回调函数
									console.log('分享取消');
								}
							});
						});

					}
				})
				// 如果微信JS-SDK未准备好，则等待
				if (!wx.config || !wx.ready) {
					setTimeout(this.onShare, 100);
				}
			} else {
				console.error('微信JS-SDK未正确加载');
			}
		}
	}
}
</script>

<style>
/* @import './assets/css/common.css'; */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	padding-bottom: constant(safe-area-inset-bottom);
	/* 兼容 iOS < 11.2 */
	padding-bottom: env(safe-area-inset-bottom);
	/* 兼容 iOS >= 11.2 */
	min-height: 100%;
	background: #f5f6fa;
	font-family: PingFangSC-Light, helvetica, Heiti SC;
}

.van-tab {
	z-index: 999999;
}

.van-sidebar-item--select::before {
	background-color: #0281fe !important;
}

.page-box {
	position: relative;
	z-index: 2;
	padding: 15px;
	border-radius: 15px;
	background-color: #FFF;
	margin-bottom: 14px;
}

.page-box-title {
	font-size: 18px;
	color: #414141;
	font-weight: bold;
}

.page-box-subtitle {
	font-size: 14px;
	color: #414141;
	font-weight: bold;
}

.schoolDetail .van-grid-item__content {
	padding: 0 !important;
}

.schoolDetail .page-box-subtitle {
	font-size: 16px;
	margin-bottom: 20px;
}

.schoolDetail .page-box {
	font-size: 13px;
}
</style>